import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Media } from "react-bootstrap"
import LandingHeader from "../components/landingHeader"
import CountUp from "react-countup"
import ngcontent1 from "../images/prolacs-ng-content-2.webp"
import LandingFeatures from "../components/landingFeatures"
import prolacsEarth from "../images/prolacs-earth.webp"
import laundry40 from "../images/prolacs-laundry40.png"
import prolacsWasher from "../images/prolacs-icon1.png"
import prolacsDryer from "../images/prolacs-icon2.png"
import prolacsTolon from "../images/prolacs-tolon.webp"
import prolacsLMSHOME from "../images/prolacs-lms-lp.webp"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {}

  render() {
    const SubscribeButtonWrapper = styled.div`
      display: flex;
      text-align: center;
      width: 38rem;
      margin-top: 0.2rem;
      margin-bottom: 1rem;
      justify-content: space-evenly;
      margin-right: auto;
      margin-left: auto;
      @media (max-width: 600px) {
        width: auto;
        flex-flow: column;
      }
    `
    const SubscribeButton = styled.div`
      background-color: #6efbc5;
      padding: 0.5rem;
      color: #333333;
      font-weight: 500;
      @media (max-width: 600px) {
        margin-top: 1rem;
      }
    `
    const ColEarth = styled(Col)`
      @media (max-width: 600px) {
        margin-top: 6rem;
      }
    `
    const SubsCol = styled(Col)`
       {
        padding-left: -15px;
        padding-right: -15px;
        margin-top: 13rem;
      }
      @media (max-width: 600px) {
        margin-top: 15rem;
      }
    `
    return (
      <Layout>
        <SEO
          title="Home"
          description="Prolacs has a new generation Artificial Intelligence IoT solutions for laundries designed with industry 4.0 inspiration."
        />
        <Container>
          <LandingHeader />
        </Container>

        <SubsCol>
          <div className="prolacs-divider" style={{ height: "100px" }}>
            <div
              className="prolacs-divider-content-1"
              style={{ top: "22px", position: "relative" }}
            >
              <p>
                {" "}
                Prolacs can <span className="color-content"></span> every
                parameter you want to know.
              </p>
              <SubscribeButtonWrapper>
                <Link to="/contact">
                  <SubscribeButton>Request a Demo </SubscribeButton>
                </Link>
              </SubscribeButtonWrapper>
            </div>
          </div>
        </SubsCol>
        <LandingFeatures />
        <Container>
          <div className="prolacs-divider-title">
            <h1>
              <b>New Generation </b>Laundry
            </h1>
          </div>
          <h1></h1>

          <Row>
            <Col sm>
              <img
                className="prolacs-ng-content-1"
                src={prolacsLMSHOME}
                alt="proalcs LMS  Laundry Management System "
              />
              <div className="prolacs-col-title-divider">
                <div className="lp-solutions-title">
                  <Link
                    className="lp-solutions-title-link"
                    to="/solutions/laundry-management-system"
                  >
                    <h1> Prolacs LMS (Laundry Management System)</h1>
                  </Link>
                </div>
              </div>
              <div className="prolacs-col-content">
                <p>
                  LMS is the professional laundry solution that lets you control
                  your businesses end to end. It has a modular and scalable
                  structure suitable for every business.It increases the
                  profitability of your business with modules such as
                  consumption management, personnel productivity and overal
                  equipment efficiency.{" "}
                </p>
              </div>
            </Col>
            <Col sm>
              <img className="prolacs-ng-content-1" src={ngcontent1} alt="" />
              <div className="prolacs-col-title-divider">
                <div className="lp-solutions-title">
                  <Link
                    className="lp-solutions-title-link"
                    to="/solutions/smart-laundromat-control"
                  >
                    <h1> Prolacs SLC (Smart Laundromat Control)</h1>
                  </Link>
                </div>
              </div>
              <div className="prolacs-col-content">
                <p>
                  Prolacs has a new generation IoT solutions for laundries
                  designed with industry 4.0 inspiration. With Prolacs, you are
                  able to view and manage your laundry departments and
                  self-service laundromats at any time from anywhere.
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="prolacs-tolon-block-background">
          <Row className="prolacs-lp-block-bg">
            <Col sm>
              <img
                className="prolacs-tolon"
                src={prolacsTolon}
                alt="prolacs-product"
              />
            </Col>
            <Col sm style={{ placeContent: "center", display: "grid" }}>
              <h1>
                Industrial controller design
                <br></br>
                is serious business <br></br>
              </h1>
              <p>
                With 14 years of knowledge and experience, Prolacs produces
                industrial washer/drier controllers and sensors. <br></br>
                <br></br>
              </p>
            </Col>
          </Row>
        </div>

        <Row className="counter-row">
          <Col sm className="counter-1">
            <h1>
              {" "}
              <CountUp end={60000} duration={20} />+
            </h1>
            <h1>Controllers</h1>
          </Col>
          <Col sm className="counter-2">
            <h1 style={{ marginTop: "1rem" }}>
              {" "}
              <CountUp end={85000} duration={20} />+
            </h1>
            <h1>Sensors</h1>
          </Col>
        </Row>

        <Row className="prolacs-allover-row">
          <Col sm className="prolacs-allover-content">
            <h1>Prolacs all over the world</h1>
            <p>
              More than 70 countries around the world are using prolacs
              products.
            </p>
          </Col>
          <ColEarth sm style={{ textAlign: "-webkit-center" }}>
            <img
              className="prolacs-earth"
              src={prolacsEarth}
              alt="prolacs-product"
            />
          </ColEarth>
        </Row>
        <Container>
          <div className="prolacs-lp-products">
            <div className="prolacs-divider-title">
              <br></br>
              <br></br>
              <h1>Products</h1>
            </div>

            <ul className="list-unstyled">
              <Media
                as="li"
                className="prolacs-lp-products-body"
                style={{ marginBottom: "2.7rem" }}
              >
                <img
                  width={120}
                  height={130}
                  className="mr-3"
                  src={laundry40}
                  alt="prolacs-product"
                  style={{ marginRight: "10px" }}
                />
                <Media.Body>
                  <h4 className="controllerheadtext">
                    Data Collection Terminals
                  </h4>
                  <p style={{ paddingRight: "40%" }} className="controllertext">
                    Data Collection Terminals are designed in accordance with
                    Industry 4.0 standards. Monitors and stores laundry machines
                    and environment parameters in real time. You can create
                    graphs and tables and get reports in seconds. With advanced
                    rule management modules, it notifies you with the
                    notification management you specify. (Phone Call, SMS, Email
                    and Siren)
                  </p>
                </Media.Body>
              </Media>

              <Media
                as="li"
                className="prolacs-lp-products-body"
                style={{ flexFlow: "row-reverse", marginBottom: "2.7rem" }}
              >
                <img
                  width={120}
                  height={150}
                  className="mr-3"
                  src={prolacsDryer}
                  style={{ marginLeft: "1rem" }}
                  alt="prolacs-product"
                />
                <Media.Body>
                  <h4
                    className="controllerheadtext"
                    style={{ float: "right", textAlign: "right!important" }}
                  >
                    Tumble Dryer Controllers
                  </h4>
                  <p style={{ paddingLeft: "40%" }} className="controllertext">
                    Dust and vibration resistant Dryer Controllers suitable for
                    industrial conditions. We make size and designs according to
                    the needs of our customers. We have solutions from basic
                    models to advanced devices that can go to the cloud.
                  </p>
                </Media.Body>
              </Media>

              <Media
                as="li"
                className="prolacs-lp-products-body"
                style={{ marginBottom: "2.7rem" }}
              >
                <img
                  width={120}
                  height={150}
                  className="mr-3"
                  src={prolacsWasher}
                  alt="prolacs-product"
                />
                <Media.Body>
                  <h4 className="controllerheadtext">
                    Tumble Washer Controllers
                  </h4>
                  <p style={{ paddingRight: "40%" }} className="controllertext">
                    Dust and vibration resistant Washer Controllers suitable for
                    industrial conditions. We make size and designs according to
                    the needs of our customers. We have solutions from basic
                    models to advanced devices that can go to the cloud.
                  </p>
                </Media.Body>
              </Media>
            </ul>
          </div>
        </Container>
      </Layout>
    )
  }
}

export default IndexPage

